import React, { useState } from "react";
import { Button, Input, Form, Message } from "semantic-ui-react";

import axios from 'axios';
import bcrypt from 'bcryptjs';
import config from '../config.json';

function SignUp () {

    async function signingUp(formData) {
        
        try {
            const hashedPassword = bcrypt.hashSync(formData.password, config.salt);
            var data = {
                surname: formData.username,
                tel: formData.phone,
                telegram: formData.telegram,
                password: hashedPassword,
                email: formData.email
            }
            axios.post(config.backUrl + '/signup', data)
            .catch(error => {
                console.error(error);
            });
        } catch (error) {
            console.error("Erreur lors du hachage du mot de passe :", error);
        }
    }

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        email: "",
        phone: "",
        telegram: ""
      });

      const [errorMessageHidden, setErrorMessageHidden] = useState(true);
    
      // Fonction pour gérer les changements dans les champs de formulaire
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      // Fonction pour soumettre le formulaire
      const handleSubmit = (e) => {
        e.preventDefault();
        // Envoyer les données au serveur
        if (formData.password==formData.confirmPassword){
            signingUp(formData);
        } else {
            setErrorMessageHidden(false);
            setTimeout(() => {
                setErrorMessageHidden(true);
            }, 3000);
            return;
        }
        // Réinitialiser les champs du formulaire après soumission
        setFormData({
          username: "",
          password: "",
          confirmPassword: "",
          email: "",
          phone: "",
          telegram: ""
        });
      };
    
      return (
        <div>
          <Form onSubmit={handleSubmit} style={{backgroundColor: "rgba(0, 0, 0, 0.6)"}}>
            <Form.Field>
              <label style={{color:"white"}}>Pseudo</label>
              <Input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <label style={{color:"white"}}>Mot de passe</label>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <label style={{color:"white"}}>Confirmer le mot de passe</label>
              <Input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <label style={{color:"white"}}>Email</label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <label style={{color:"white"}}>Téléphone</label>
              <Input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label style={{color:"white"}}>Telegram</label>
              <Input
                type="text"
                name="telegram"
                value={formData.telegram}
                onChange={handleChange}
              />
            </Form.Field>
            <Button type="submit">Créer un compte</Button>
          </Form>
        </div>
      );
    }
    
    export default SignUp;
