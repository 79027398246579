import React from "react";
import { Button, Modal, ModalContent, ModalDescription, ModalHeader } from "semantic-ui-react";
import TextWithLineBreaks from "../../../utils/testWithBreaks";

function ActivityModal({
    open, setOpen, 
    title, 
    type,
    schedule,
    nb_players,
    capacity,
    description,
    image 
    }) {

    return (
        <Modal
            onClose={()=>setOpen(false)}
            // onOpen={() => setOpen(true)}
            open={open}
            closeOnEscape={true}
            closeOnDimmerClick={true}   
        >
            <ModalHeader>
                <div style={{ padding:4, display: 'flex', justifyContent: 'space-between' }}>
                    <span>{title}</span>
                    <span>{type}</span>
                </div>
                <div style={{ padding:4, display: 'flex', justifyContent: 'space-between' }}>
                    <span>{schedule}</span>
                </div>
            </ModalHeader>
            <ModalContent>
                Nombre de joueurs : {nb_players}/{capacity}
            </ModalContent>
            <ModalContent>
                <div>
                    Synopsis : 
                </div>
                <ModalDescription>
                    <TextWithLineBreaks text={description}/>
                </ModalDescription>
            </ModalContent>
            
        </Modal>
    )
}

export default ActivityModal
