import React, { useEffect } from "react";

function Welcome () {
    return (
        <div style={{backgroundColor: "rgba(1, 0, 0, 0.8)", color:"white", fontSize: "20px", padding:"10px", lineHeight: "1.5"}}>
            Bienvenue sur le site des Enrôlé·es, un événement centré autour du jeu de rôle. Inscris-toi aux différents scénarios proposés par nos MJ volontaires sur tout le week-end et rejoins l'un des nombreux univers disponibles. Deviens un chasseur de démons, un cowboy de l'espace ou un gobelin magicien pour quelques heures! Rejoins-nous à CentraleSupélec le 18 et 19 mai 2024
        </div>
    )
}

export default Welcome;
