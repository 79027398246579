import React, { useEffect, useState } from "react";
import ActivityCard from "../components/activity/card/activityCard";
import { CardGroup } from "semantic-ui-react";
import testImg from "./../testimg/testimg.png";
import config from '../config';
import axios from 'axios';

function Activities () {

    const [activities, setActivities] = useState([]);

    useEffect(() => {
        axios.get(config.backUrl + '/jdr')
        .then((response) => {
            setActivities(response.data);
        })
    },[])

    return (
        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 20px)', maxWidth: '100%' }}>
          <CardGroup style={{ paddingBottom: '50px'}}>
            {Array.isArray(activities) && activities.map((activity) => (
              <ActivityCard
                key={activity.id}
                identifier={activity.id}
                title={activity.title}
                type={activity.type}
                schedule={activity.schedule}
                description={activity.description}
                capacity={activity.capacity}
                nb_players={0}
                style={{ margin: '10px' }}
              />
            ))}
          </CardGroup>
        </div>
      </div>
    </div>
    )
}

export default Activities
