import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardContent, CardMeta, Button, Image } from "semantic-ui-react";
import TruncatedText from "../../utils/troncatedText/troncatedText";
import ActivityModal from "../modal/activityModal";

import cadre from './ressources/cadre2.png';
import murderToken from './ressources/murder.png';
import osToken from './ressources/os.png';
import axios from 'axios';
import config from '../../../config.json';

function ActivityCard({
    identifier,
    title,
    type,
    schedule,
    capacity,
    description,
    image
}) {
    const [openModal, setOpenModal] = useState(false);
    const [nbPlayers, setNbPlayers] = useState(0);
    const [registered, setRegistered] = useState(false);

    useEffect(() => {
        axios.get(config.backUrl +'/nbplayers/'+identifier)
        .then((response) => {
            setNbPlayers(response.data);
        })
        const username = localStorage.getItem("user");
        const data = {
            "username" : username,
            "activityId" : identifier
        }
        axios.get(config.backUrl +`/participation?username=${username}&activityid=${identifier}`)
        .then((response) => {
            if (response.data.length>0){
                setRegistered(true);
            }
            else {
                setRegistered(false);
            }
        })
    }, [])

    function participate() {
        const username = localStorage.getItem("user");

        if (username) {
            const data = {
                "username" : username,
                "activityId" : identifier
            }

            axios.post(config.backUrl +'/participate', data)
            .then((response) => {
                setRegistered(true);
            })
        }

        
    }

    function unregister() {
        const username = localStorage.getItem("user");

        if (username) {
            const data = {
                "username" : username,
                "activityId" : identifier
            }

            axios.post(config.backUrl +'/unregister', data)
            .then((response) => {
                setRegistered(false);
            })
        }
    }

    const handleCardClick = (event) => {
        // Vérifiez si l'élément cliqué est le bouton "S'inscrire"
        if (event.target.tagName !== "BUTTON") {
            setOpenModal(true);
        }
    };

    return (
        <>
            <ActivityModal 
                open={openModal} 
                setOpen={setOpenModal}
                title={title}
                type={type}
                schedule={schedule}
                nb_players={nbPlayers}
                capacity={capacity}
                description={description}
                image={image}

            />
            <Card 
                href='#activity-card'
                onClick={handleCardClick}
                style={{ 
                    position: 'relative', 
                    width: '300px', height:'450px',
                    padding:'40px',
                    backgroundColor: "rgba(0,0,0,0)", 
                    background: `url(${cadre})` ,
                    backgroundSize: '100%',
                    backgroundPosition: "center",
                    boxShadow: 'none',
                }}
            >

                <div style={{ position: 'absolute', top:0, right:0, width: "110px", height: "90px"}}>
                    {type == "OS" ? 
                    (<img src={osToken} style={{ width: '100%', height: 'auto', objectFit: 'cover'}}/>) 
                    : (<img src={murderToken} style={{ width: '100%', height: 'auto', objectFit: 'cover'}}/>)}
                </div>

                

                <CardHeader>
                    <div style={{  
                        paddingLeft:'20px', paddingRight: '20px', paddingBottom: '10px', paddingTop: '5px',
                        display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <span className="text title">{title}</span>
                        <div style={{width:"60px"}}></div>
                    </div>
                </CardHeader>
                <CardMeta>
                    <div className="text" style={{ padding:4, display: 'flex', justifyContent: 'space-between' }}>
                    {schedule}
                    </div>
                </CardMeta>
                <CardContent className="text" style={{flexGrow: 0}}>Nombre de joueurs : {nbPlayers}/{capacity}</CardContent>
                <CardContent style={{flexGrow: 0}}>
                    <div className="text">
                        Synopsis :   
                    </div>
                    <div className="text-container text">
                        <TruncatedText text={description} maxCharacters={100}/>
                    </div>
                    
                </CardContent>
                <CardContent style={{justifyContent:'center', display: 'flex'}}>
                    
                    <Button color='red' onClick={registered ? unregister : participate}>
                    {registered ? "Se désinscrire" : "S'inscrire"}
                    </Button>
                    
                    
                </CardContent>
            </Card>
        </>
        
    )
}

export default ActivityCard
