import React, { useState } from "react";
import isLogged from "../utils/isLogged";
import { Navigate } from "react-router-dom";

function Logout() {
    const [logged, setLogged] = useState(isLogged());

    if (logged) {
        window.localStorage.removeItem("user");
        window.location.reload();
    }
    
    return <Navigate to="/"/>;
}

export default Logout;