import 'semantic-ui-css/semantic.min.css'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import isLogged from './utils/isLogged'; 
import Welcome from './pages/welcome';
import SignIn from './pages/signIn';
import SignUp from './pages/signUp';
import Activities from './pages/activities';
import NavBarLogged from './components/navBar/navBarLogged';
import NavBar from './components/navBar/navBar';
import Logout from './pages/logout';


function App() {

  if (isLogged()){
    const Content = () => (
      <Routes>
        <Route path="/" element={<Welcome/>}/>
        <Route path="/jdr" element={<Activities/>}/>
        <Route path="/log-out" element={<Logout/>}/>
        <Route path="/sign-in" element={<SignIn/>}/>
      </Routes>
    )
    return (
      <>
        
        <Router>
          <NavBarLogged/>
          <Content/>
        </Router>

      </>
      
    );
  }
  const Content = () => (
    <Routes>
      <Route path="/" element={<Welcome/>}/>
      <Route path="/sign-in" element={<SignIn/>}/>
      <Route path="/sign-up" element={<SignUp/>}/>
      <Route path="/jdr" element={<Activities/>}/>
      <Route path="/log-out" element={<Logout/>}/>
    </Routes>
  )
  //dont forget to switch to NavBar when login sytem is done
  return (
    <>
    <Router>
      <NavBar/> 
      <Content/>
    </Router>

  </>
  )
}

export default App;
