import React, { useState } from "react";
import bcrypt from 'bcryptjs';
import axios from "axios";
import { Button, Form, Input } from "semantic-ui-react";
import config from '../config.json';
import { Navigate } from 'react-router-dom';
import isLogged from "../utils/isLogged";

function SignIn () {

    const [logged, setLogged] = useState(isLogged());

    function signingIn(formData) {
        const hashedPassword = bcrypt.hashSync(formData.password, config.salt);
        var data = {
            surname: formData.username,
            password: hashedPassword
        };
        axios.post(config.backUrl + '/login', data)
        .then(response => {
            if (response.data == formData.username){
                window.localStorage.setItem("user", response.data);
                window.location.reload();
            }    
            
        })
        .catch(error => {
            console.error(error);
        })
    }

    const [formData, setFormData] = useState({
        username: "",
        password: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

    const handleSubmit = (e) => {
    e.preventDefault();
    // Envoyer les données au serveur
    
    signingIn(formData);
        
    // Réinitialiser les champs du formulaire après soumission
    setFormData({
        username: "",
        password: "",
    });
    };

    if (logged) {
        return <Navigate to="/" />;
    }

    return (
        <Form onSubmit={handleSubmit} style={{backgroundColor: "rgba(0, 0, 0, 0.6)"}}>
            <Form.Field>
                <label style={{color:"white"}}>Pseudo :</label>
                <Input
                   type="text"
                   name="username"
                   value={formData.username}
                   onChange={handleChange}
                   required 
                />
            </Form.Field>
            <Form.Field>
                <label style={{color:"white"}}>Mot de Passe :</label>
                <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
            </Form.Field>
            <Button type="submit">Se Connecter</Button>
        </Form>
    );
}

export default SignIn;
