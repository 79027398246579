import React from 'react';
import TextWithLineBreaks from '../../../utils/testWithBreaks';

function TruncatedText({ text, maxCharacters }) {
  const truncatedText = text.length > maxCharacters ? text.substring(0, text.lastIndexOf(' ', maxCharacters)) + '...' : text;

  return (
    <TextWithLineBreaks className="truncated-text" text={truncatedText}/>
  );
}

export default TruncatedText
