import React from "react";

import { Link } from "react-router-dom";
import { Button, Grid } from 'semantic-ui-react';

function NavBarButton({ title, path }) {

    return (
        <Grid.Column width={12} textAlign="center" style={{ padding: 0, height: '60px', flex:1 }}>
            <Grid textAlign="center" style={{ padding: 0, margin: 0, height: '60px' }}>
                
                <Link className="text-white" to={path}>
                    <Button color="red" style={{ height: '60px' }}>
                        {title}
                    </Button>
                </Link>
                
            </Grid>
        </Grid.Column>
    )
}

export default NavBarButton