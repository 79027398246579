import React from "react";

import { Link } from "react-router-dom";
import { Button, Grid, Image } from 'semantic-ui-react';
import { ReactComponent as Logo } from "./ressources/LogoFinal.svg";


function NavBarLogoButton({ path }) {

    return (
        <Grid.Column width={12} textAlign="center" style={{ padding: 0, height: '60px', flex:1 }}>
            <Grid textAlign="center" style={{ padding: 0, margin: 0, height: '60px' }}>
                <Link className="text-white" to={path} style={{ height:"100%", position:"absolute", left:0}}>
                    <Logo/> 
                </Link>
            </Grid>
        </Grid.Column>
    )
}

export default NavBarLogoButton