import React from "react";

import { Grid } from 'semantic-ui-react';
import NavBarButton from "./navBarButton";
import NavBarLogoButton from "./navBarLogoButton";

function NavBarLogged() {

    return(
        
            
            <Grid className="menu" verticalAlign="middle" style={{ display: 'flex', backgroundColor: 'black'  }}>
                {/* <Grid.Column width={2} style={{ padding: 0, height: '60px' }}>
                    <a id="logolien" href={urlAccueil}><img src={logo} alt="logo" id="logo" mask="mask1"/></a>
                </Grid.Column> */}
                <NavBarLogoButton path="/"/>
                <NavBarButton title="Activités" path="/jdr"/>
                <NavBarButton title="Se déconnecter" path="/log-out"/>
            </Grid> 
        
    )
}

export default NavBarLogged